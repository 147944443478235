import React, { useState } from 'react';
import '../../styles/style.scss';
import Layout from '../../components/layout';
import Careers from '../../components/sections/careers';
import LicensedIn from '../../components/licensed-in';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout activePage='resources' {...props}>
      <div className='resources-container'>
        <div className='resources-texts'>
          <div className='resources-texts-hero'>APOLLO INSURANCE RESOURCES</div>
          <div className='resources-texts-text'>
            We help all our clients get the best coverage possible at the most affordable price. We are experts in this industry and do whatever we can to be
            your helpful, trusted, and knowledgeable advisor, all while keeping you up to date.
          </div>
        </div>
        <div className='resources-sections'>
          <a href={'/news'}>
            <div className='resources-section resource-section-news'>
              <div className='resources-section-title'>NEWS</div>
              <div className='resources-section-sub-title'>Keeping You Up To Date</div>
            </div>
          </a>
          <a href={'/resources/glossary'}>
            <div className='resources-section resource-section-glossary'>
              <div className='resources-section-title'>GLOSSARY</div>
              <div className='resources-section-sub-title'>Lean More About The Universe Of Health Insurance</div>
            </div>
          </a>
        </div>
      </div>
      <LicensedIn {...props} />
    </Layout>
  );
};
